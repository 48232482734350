
// import libs
// import './libs/cookieBar';

// var $header = $('#header');
// var headerHeight = $header.outerHeight();

// import components
import './components/ie-detection.js';
import './components/menu.js';
import './components/pinnedSection.js';
import './components/choicesJs.js';
// import './components/modal.js';

// init
// $('.lazy').Lazy({
//     // your configuration goes here
//     scrollDirection: 'vertical',
//     effect: 'fadeIn',
//     effectTime: 150,
//     visibleOnly: true,
//     threshold: 500,
//     bind: 'event',
//     onError: function(element) {
//         console.log('error loading ' + element.data('src'));
//     }
// });

// AOS animations
AOS.init({
    // once: true,
    disable: 'mobile'
});

// ----------------------------------------
//  scroll to
var $scrollToItems = $('.scrollTo');
$.each($scrollToItems, function() {
    $(this).on('click', function(e) {
        e.preventDefault();

        var href = $(this).attr('href');
        if (typeof href === 'undefined') {
            href = $(this).attr('data-href');
        }
        var offset = $(this).attr('data-offset');

        // if booking strip scroll to
        if ($(this).hasClass('scrolling-strip')) {
            const mq = window.matchMedia( "(max-width: 576px)" );
            if (mq.matches) {
                offset = 0;
            }
        }

        var $target = $(href);
        if ($target.length) {
            $('html,body').animate({
                scrollTop: $target.offset().top - headerHeight + ((typeof offset !== 'undefined') ? parseInt(offset) : 0)
            }, {
                duration: 300,
                easing: 'swing'
            });
        }
    });
});
// ----------------------------------------

// scripts here
// ----------------------------------------

// Bootstrap dropdown
// $('.dropdown-toggle').dropdown();

// collapse
// $('.collapse__toggle, .collapse__toggle > label').on('click', function(e) {
//     $(this).closest('.collapse').toggleClass('show');
// });

// $('.page--detail').stickem({
//     offset: 100,
// });

var swiperFeatures = new Swiper("#swiperFeatures", {
    slidesPerView: 1,
    spaceBetween: 16,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
    breakpoints: {
        578: {
            slidesPerView: 2,
          },
        992: {
          slidesPerView: 3,
        },
    },
});

var swiperTestimonials = new Swiper("#swiperTestimonials", {
    // slidesPerView: 1,
    spaceBetween: 100,
    speed: 1000,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
});

var swiperScreenshots = new Swiper(".swiper--screenshots", {
    spaceBetween: 30,
    // speed: 500,
    effect: "fade",
    crossFade: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
    loop: true,
});

var swiperStripe = new Swiper(".swiper--stripe", {
    slidesPerView: 3,
    spaceBetween: 30,
    speed: 1500,
    // effect: "fade",
    // crossFade: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
    loop: true,
    breakpoints: {
        578: {
            slidesPerView: 4,
        },
        992: {
          slidesPerView: 6,
        },
    },
});

// Select2
function initSelect2() {
    $('.select2').select2();
}
initSelect2();

// document.addEventListener("DOMContentLoaded", () => {
//     Livewire.hook('message.processed', (message, component) => {
//         console.log(component.el);
//         // if ($(component.el).attr('id') == 'filter') {
//         //     initSelect2();
//         // }
//     });
// });
