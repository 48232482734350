;(function ($) {

	'use strict';

	// gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

	// const mqLargeScreen = window.matchMedia( "(min-width: 992px)" );

	// if (mqLargeScreen.matches) {

	// 	gsap.to(section, {
	// 		scrollTrigger: {
	// 			trigger: section,
	// 			start: "top top",
	// 			end: `+=${steps * scrollingAreaOnOneStep}%`,
	// 			pin: true,
	// 			scrub: true,
	// 			onUpdate: handlePhoneSectionUpdate, // Funkce pro aktualizaci animace při scrollu
	// 		}
	// 	});
		
	// }

// test for position: sticky - zadny nadrizeny element nesmi mit overflow krom visible
// let parent = document.querySelector('.sticky').parentElement;
// while (parent) {
//   const hasOverflow = getComputedStyle(parent).overflow;
//   if (hasOverflow !== 'visible') {
//     console.log(hasOverflow, parent);
//   }
//   parent = parent.parentElement;
// }

	var waypointsPinnedDown = $('.pinned-slide-waypoint').waypoint(function(direction) {
		
		if (direction === 'down') {
			let $el = $(this.element);
			let index = $el.attr('data-content');

			let $items = $el.closest('.section--pinned').find('.pinnedContent__item');
			$items.removeClass('active');

			$items.eq(index - 1).addClass('active');
		}		
 
	}, {
		offset: '75%'
	});

	var waypointsPinnedUp = $('.pinned-slide-waypoint').waypoint(function(direction) {
		
		if (direction === 'up') {
			let $el = $(this.element);
			let index = $el.attr('data-content');

			let $items = $el.closest('.section--pinned').find('.pinnedContent__item');
			$items.removeClass('active');

			$items.eq(index - 1).addClass('active');
		}		
 
	}, {
		offset: '0%'
	});


})(jQuery);