;(function ($) {

    'use strict';

    $('.choices-js').each(function() {
        const choices = new Choices(this, {
            searchEnabled: true,
            placeholder: true,
            searchPlaceholderValue: 'Search',
            itemSelectText: '',
            shouldSort: false,
        });
    })
})(jQuery);
