

// mobile menu scripts
;(function ($) {

  "use strict";

  // const mqMobile = window.matchMedia( "(max-width: 575px)" );
  // const mqTablet = window.matchMedia( "(min-width: 576px) and (max-width: 1199px)" );
  // var offsetCompactHeader = 25;
  // if (mqTablet.matches) {
  //   offsetCompactHeader = 25;
  // }

  // var offsetBgHeader = 250;
  // if (mqTablet.matches) {
  //   offsetBgHeader = 50;
  // }

  // Hide Header on on scroll down
  var $header = $('#header');
  // let lastScroll = 0;
  // window.addEventListener("scroll", () => {
  //   const currentScroll = window.pageYOffset;
  //   if (currentScroll <= 0) {
  //     $header.addClass('header--down');
  //     return;
  //   }
  
  //   if (currentScroll > lastScroll && !$header.hasClass('header--up')) {
  //     // down
  //     $header.removeClass('header--down');
  //     $header.addClass('header--up');
  //     // lottiePlayer.play();
  //   } else if (currentScroll < lastScroll && $header.hasClass('header--up')) {
  //     // up
  //     // body.classList.remove(scrollDown);
  //     // body.classList.add(scrollUp);

  //     $header.removeClass('header--up');
  //     $header.addClass('header--down');

  //     // lottiePlayer.stop();
  //   }
  //   lastScroll = currentScroll;
  // });

    // burger
    $('.nav__toggle').on('click', function(e){
      e.preventDefault();
      $('.nav__toggle').toggleClass('is-active');
      $('body').toggleClass('menu-active');
    });

    $('.nav__overlay').on('click', function(e) {
      $('.nav__toggle').removeClass('is-active');
      $('body').removeClass('menu-active');
    });

    // $('.nav__item, .menu-item').on('click', function(e) {
    //   $('.nav__toggle').removeClass('is-active');
    //   $('body').removeClass('menu-active');
    // });

    // $('.nav__toggleOpen').on('click', function(e) {
    //   e.preventDefault();
    //   $('.nav__toggle').addClass('is-active');
    //   $('body').addClass('menu-active');

    //   $('body').removeClass('search-active').removeClass('locale-active').removeClass('registration-active');
    // });

    // var waypointSmall = new Waypoint({
    //   element: document.getElementById('showSmallHeader'),
    //   handler: function(direction) {
    //     if (direction == 'up') {
    //       $('#header').removeClass('header--show');
    //       console.log('hide');
    //     }
    //     else {
    //       $('#header').addClass('header--show');
    //       console.log('show');
    //     }
    //   },
    //   offset: '0%'
    // });

    // $('#showSmallHeader').waypoint({
    //   handler: function(direction) {
    //     if (direction == 'up') {
    //       $('#teaserSubnav').removeClass('fixed');
    //     }
    //     else {
    //       $('#teaserSubnav').addClass('fixed');
    //     }
    //   },
    //   offset: 0
    // });

    // var waypointResizeMenu = new Waypoint({
    //   element: document.getElementsByTagName('body'),
    //   handler: function(direction) {
    //     if (direction == 'up') {
    //       $header.removeClass('header--compact');
    //     }
    //     else {
    //       $header.addClass('header--compact');
    //     }
    //   },
    //   offset: -offsetCompactHeader
    // });

    // var waypointBgMenu = new Waypoint({
    //   element: document.getElementsByTagName('body'),
    //   handler: function(direction) {
    //     if (direction == 'up') {
    //       $header.removeClass('header--bg');
    //     }
    //     else {
    //       $header.addClass('header--bg');
    //     }
    //   },
    //   offset: -offsetBgHeader
    // });

    $('.header__overlay').on('click', function(e) {
      $('.nav__toggle').removeClass('is-active');
      $('body').removeClass('menu-active');
      $('body').removeClass('filter-active');
    });

    // var waypoints = $('.enableHeaderBg').waypoint(function(direction) {
    //   if (direction == 'up') {
    //     $header.removeClass('header--bg');
    //   }
    //   else {
    //     $header.addClass('header--bg');
    //   }
    // }, {
    //   offset: '0%'
    // });


    // https://stackoverflow.com/questions/31223341/detecting-scroll-direction
    var lastScrollTop = 0;

    // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
    function setHeaderClassOnScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"

      // console.log(st);
      // console.log(lastScrollTop);
      if (st > lastScrollTop){
          // downscroll code
          $header.addClass('scroll scroll--down');   
          $header.removeClass('scroll--up scroll--top');   
          
          // console.log('down');
          
      } else {
          // upscroll code
          if (st != 0) {
            $header.addClass('scroll scroll--up');
            $header.removeClass('scroll--down scroll--top');

            // console.log('up');
          }
          else {
            $header.removeClass('scroll scroll--down scroll--up');            
            
            // console.log('top');
          }
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }
    window.addEventListener("scroll", function(){ // or window.addEventListener("scroll"....
      setHeaderClassOnScroll();
    }, false);
    setHeaderClassOnScroll();

})(jQuery);
